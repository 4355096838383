/**
 * Class to provide geolocation services
 */
export class GeolocationService {
	/**
	 * Get the current position of the user
	 *
	 * @param {Object} options - Options for the geolocation API
	 * @param {boolean} options.enableHighAccuracy - Whether to use high-accuracy mode
	 * @param {number} options.timeout - The maximum time to wait for a position in milliseconds
	 * @param {number} options.maximumAge - The maximum age of a cached position that the API is allowed to return
	 *
	 * @returns {Promise<GeolocationPosition>} A promise that resolves with the position if it is obtained successfully
	 * @throws {Error} If the geolocation API is not supported or an error occurs while getting the position
	 */
	getCurrentPosition(options = {}) {
		return new Promise((resolve, reject) => {
			if (!navigator.geolocation) {
				reject(new Error("Geolocation is not supported by this browser."));
			} else {
				navigator.geolocation.getCurrentPosition(
					resolve,
					({ code, message }) =>
						reject(new Error(`Geolocation error (${code}): ${message}`)),
					options
				);
			}
		});
	}
}
