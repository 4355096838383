/**
 * Class representing an event handler for managing event listeners on DOM elements.
 */
export class EventHandler {
	constructor(target) {
		if (!(target instanceof Element || typeof target === "string")) {
			throw new Error("Invalid target");
		}

		this.target = target;
		this.listeners = [];

		this.initialize();
	}

	initialize() {
		const targetElements =
			typeof this.target === "string"
				? document.querySelectorAll(this.target)
				: [this.target];

		if (targetElements.length === 0) {
			throw new Error("No target element(s) found");
		}

		targetElements.forEach((targetElement) => {
			if (!targetElement) {
				throw new Error("Invalid target element");
			}

			this.listeners.push({
				targetElement,
				eventListeners: [],
			});
		});
	}

	addEventListener(eventType, callback) {
		if (typeof eventType !== "string" || !eventType.trim()) {
			throw new Error("Invalid event type");
			return;
		}

		if (typeof callback !== "function") {
			throw new Error("Invalid callback function");
			return;
		}

		this.listeners.forEach((listener) => {
			if (!listener.targetElement) {
				throw new Error("Invalid target element");
				return;
			}

			listener.targetElement.addEventListener(eventType, callback);
			listener.eventListeners.push({
				eventType,
				callback,
			});
		});
	}

	removeEventListener(eventType, callback) {
		if (typeof eventType !== "string" || !eventType.trim()) {
			throw new Error("Invalid event type");
			return;
		}

		if (typeof callback !== "function") {
			throw new Error("Invalid callback function");
			return;
		}

		this.listeners.forEach((listener) => {
			if (!listener.targetElement) {
				throw new Error("Invalid target element");
				return;
			}

			const eventListenerIndex = listener.eventListeners.findIndex(
				(listener) =>
					listener.eventType === eventType && listener.callback === callback
			);
			if (eventListenerIndex !== -1) {
				const { eventType, callback } =
					listener.eventListeners[eventListenerIndex];
				listener.targetElement.removeEventListener(eventType, callback);
				listener.eventListeners.splice(eventListenerIndex, 1);
			}
		});
	}

	destroy() {
		this.listeners.forEach((listener) => {
			if (!listener.targetElement) {
				throw new Error("Invalid target element");
				return;
			}

			listener.eventListeners.forEach(({ eventType, callback }) => {
				listener.targetElement.removeEventListener(eventType, callback);
			});
		});
	}
}
