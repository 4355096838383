/**
 * Represents an Overlay utility class.
 */
export class Overlay {
	/**
	 * Creates an instance of Overlay.
	 * @param {string[]} selectors - Selectors for elements that trigger the overlay.
	 * @param {string[]} styleSelectors - Selectors for additional elements to apply styles.
	 * @throws {Error} If no selectors are provided.
	 */
	constructor(selectors = [], styleSelectors = []) {
		if (selectors.length === 0) {
			throw new Error("At least one selector should be provided.");
		}

		this.elements = [];
		this.styleElements = [];
		this.overlay = null;
		this.overlayStyle = `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 9999;
        `;
		this.inputStyle = `
            position: relative;
            z-index: 10000;
        `;
		this.initialZIndex = null;

		this.handleElementClick = this.handleElementClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);

		selectors.forEach((selector) => {
			const elements = Array.from(document.querySelectorAll(selector));
			this.elements.push(...elements);
		});

		styleSelectors.forEach((selector) => {
			const elements = Array.from(document.querySelectorAll(selector));
			this.styleElements.push(...elements);
		});

		this.handleElementEvents();
	}

	injectOverlay() {
		if (!this.overlay) {
			this.overlay = document.createElement("div");
			this.overlay.id = "overlay";
			document.body.appendChild(this.overlay);
		}
	}

	applyOverlayStyles() {
		if (!this.overlay) {
			throw new Error("Overlay element not created.");
		}

		this.overlay.style.cssText = this.overlayStyle;
	}

	applyElementStyles() {
		const initialZIndex = this.styleElements[0].style.zIndex;
		this.styleElements.forEach((element) => {
			element.style.cssText = `${this.inputStyle}; z-index: ${initialZIndex}`;
		});
	}

	resetElementStyles() {
		this.styleElements.forEach((element) => {
			element.style.removeProperty("z-index");
		});
		this.initialZIndex = null;
	}

	removeOverlay() {
		if (this.overlay && this.overlay.parentNode) {
			this.overlay.parentNode.removeChild(this.overlay);
			this.overlay = null;
			this.resetElementStyles();
		}
	}

	handleElementClick() {
		this.elements.forEach((element) => {
			element.addEventListener("click", () => {
				this.injectOverlay();
				this.applyOverlayStyles();
				this.applyElementStyles();
			});
		});

		document.addEventListener("click", this.handleOutsideClick);
	}

	handleElementFocus() {
		this.elements.forEach((element) => {
			element.addEventListener("focus", () => {
				this.injectOverlay();
				this.applyOverlayStyles();
				this.applyElementStyles();
			});
		});
	}

	handleElementBlur() {
		this.elements.forEach((element) => {
			element.addEventListener("blur", () => {
				this.removeOverlay();
			});
		});
	}

	handleOutsideClick(event) {
		const isClickInsideElements = this.elements.some((element) =>
			element.contains(event.target)
		);

		if (!isClickInsideElements) {
			this.removeOverlay();
		}
	}

	handleElementEvents() {
		this.handleElementClick();
		this.handleElementFocus();
		this.handleElementBlur();
	}
}
