// Styles
import "../css/style.css";
import "../css/colors.css";

// Utils
import { onDOMReady } from "./Utils/utils.js";

// UI related classes
import { CollapsibleMenu } from "./CollapsibleMenu/CollapsibleMenu.class.js";
import { FormInputTransformer } from "./FormInputTransformer/FormInputTransformer.class.js";
import { MaskedInput } from "./MaskedInput/MaskedInput.class.js";
import { Overlay } from "./Overlay/Overlay.class.js";
import { EventHandler } from "./EventHandler/EventHandler.class.js";
import { InputFocus } from "./InputFocus/InputFocus.class.js";
import { ToggleVisibility } from "./ToggleVisibility/ToggleVisibility.class.js";

// Geolocation and postal code related classes
import { InputUpdater } from "./InputUpdater/InputUpdater.class.js";

// Translation related classes
import { LanguageDetector } from "./LanguageDetector/LanguageDetector.class.js";
import Translator from "./Translator/Translator.class.js";

// EventBus
import { GlobalEventBus } from "./EventBus/EventBus.class.js";
import { FormHandler } from "./FormHandler/FormHandler.class.js";
import { GeolocationService } from "./GeolocationService/GeolocationService.class.js";
import OpenStreetMapService from "./OpenStreetMapService/OpenStreetMapService.class.js";
import { LocationRetriever } from "./LocationRetriever/LocationRetriever.class.js";
import { PostalCodeRangeChecker } from "./PostalCodeRangeChecker/PostalCodeRangeChecker.class.js";

// Constants
import {
	SUPPORTED_LANGUAGES,
	ORIGIN_POSTAL_CODE,
	EVENT_LOCATION_RETRIEVED,
	EVENT_INPUT_UPDATED,
	ELEMENT_IDS,
	CSS_CLASSES,
} from "./constants.js";

// Dom elements and event handlers
const DOM = {
	setupNavbar,
	handleFAQItems,
	initializeComponents,
	setupEventListeners,
};

// Run when DOM is ready
onDOMReady(() => {
	Object.values(DOM).forEach((fn) => fn());
});

function setupNavbar() {
	const callLinkWithVisibleIcons = new ToggleVisibility(
		"call-link",
		"icons-container",
		"ToggledEvent",
		"HideEvent"
	);
	const chatLinkWithVisibleIcons = new ToggleVisibility(
		"chat-link",
		"icons-container2"
	);

	const navbar = document.querySelector("nav");
	const navbarHeight = navbar.offsetHeight;
	document.documentElement.style.setProperty(
		"--navbar-height",
		`${navbarHeight}px`
	);

	const menuToggleElement = document.querySelector("#menu-open");
	const eventHandler = new EventHandler(menuToggleElement);
	const collapsibleMenu = new CollapsibleMenu("#menu-open", "#menu-items");

	eventHandler.addEventListener("click", () => {
		collapsibleMenu.toggleMenu();
	});
}

function handleFAQItems() {
	const faqItems = document.querySelectorAll(".faq-item");
	faqItems.forEach((item) => {
		const summary = item.querySelector("summary");
		const arrow = summary.querySelector(".faq-arrow");

		summary.addEventListener("click", () => {
			arrow.classList.toggle("expanded");
		});
	});
}

function setupFormHandler() {
	return new FormHandler("quoteForm");
}

function initializeComponents() {
	const inputElement = document.getElementById("postalCodeInput");
	const languageDetector = new LanguageDetector(SUPPORTED_LANGUAGES);
	const detectedLanguage = languageDetector.detectLanguage();
	const inputUpdater = new InputUpdater([inputElement]);

	const formHandler = setupFormHandler();

	new FormInputTransformer();
	new MaskedInput(inputElement);
	new InputFocus();
	new Translator(detectedLanguage);
	new Overlay(["#postalCodeInput"], ["#hero-form-container"]);

	// Subscribe to the 'location.retrieved' event
	GlobalEventBus.subscribeMultiple(
		{
			eventType: EVENT_LOCATION_RETRIEVED,
			callback: async (event) => {
				if (event.data && event.data.postcode) {
					const postalCode = await event.data.postcode;
					inputUpdater.updateInput({ postalCodeInput: postalCode });
					checkPostalCodeAndRedirect(event.data.postcode);
				}
			},
		},
		{
			eventType: EVENT_INPUT_UPDATED,
			callback: (event) => {
				if (event.data) {
					const postalCodeInput = event.data[0];

					formHandler.validateAndDisplay(postalCodeInput);
				}
			},
		}
	);
}

// Event listeners setup
function setupEventListeners() {
	const locationSelector = document.getElementById(ELEMENT_IDS.locationIcon);
	locationSelector.addEventListener("click", handleLocationRetrieved);
	const submitButton = document.getElementById("postal_code_submit");
	submitButton.addEventListener("click", checkPostalCodeAndRedirect);
}

async function handleLocationRetrieved() {
	const geolocationService = new GeolocationService();
	const reverseGeocodingService = new OpenStreetMapService();
	const locationRetriever = new LocationRetriever(
		geolocationService,
		reverseGeocodingService
	);
	try {
		await locationRetriever.retrieveLocation();
	} catch (error) {
		console.error("Failed to retrieve location:", error);
	}
}

async function checkPostalCodeAndRedirect(postcode) {
	const modalElement = document.getElementById("modal");
	const rangeChecker = new PostalCodeRangeChecker();
	const originPostalCode = ORIGIN_POSTAL_CODE; // Replace with the actual origin postal code

	if (!postcode) {
		const targetPostalCode = document.getElementById("postalCodeInput").value;
		return targetPostalCode;
	}

	const targetPostalCode = document.getElementById("postalCodeInput").value; // Get the target postal code from the location object

	try {
		const isWithinRange = await rangeChecker.checkPostalCodeInRange(
			originPostalCode,
			targetPostalCode
		);

		if (!isWithinRange) {
			// Postal code is not within range, display modal
			modalElement.classList.remove("hidden");

			// Attach event listener to close modal
			const closeButton = modalElement.querySelector(CSS_CLASSES.modalClose);
			closeButton.addEventListener("click", () => {
				modalElement.classList.add("hidden");
			});
		}

		if (isWithinRange) {
			// Store the current URL in the browser's history
			const previousURL = window.location.href;
			history.replaceState(null, "", previousURL);

			// Redirect to the offerte-aanvragen.html page with the auto-filled data
			window.location.href = `/offerte.html?postal_code=${encodeURIComponent(
				targetPostalCode
			)}`;
		}
	} catch (error) {
		console.error("Error checking postal code range:", error);
		// Handle the error gracefully, e.g., display an error message to the user
	}
}
