export class ToggleVisibility {
	constructor(callLinkId, targetElementId, toggleEventName, hideEventName) {
		this.callLink = document.getElementById(callLinkId);
		this.targetElement = document.getElementById(targetElementId);
		this.toggleEventName = toggleEventName;
		this.hideEventName = hideEventName;

		this.callLink.addEventListener(
			"click",
			this.toggleElementVisibility.bind(this)
		);
		document.addEventListener("click", this.hideElement.bind(this));
	}

	toggleElementVisibility(event) {
		event.preventDefault();
		this.targetElement.classList.toggle("hidden");
		event.stopPropagation();

		// Dispatch the toggle event
		const toggleEvent = new CustomEvent(this.toggleEventName, {
			detail: { isVisible: !this.targetElement.classList.contains("hidden") },
		});
		document.dispatchEvent(toggleEvent);
	}

	hideElement(event) {
		const isClickedOutside =
			!event.target.closest(`#${this.callLink.id}`) &&
			!event.target.closest(`#${this.targetElement.id}`);
		if (isClickedOutside) {
			this.targetElement.classList.add("hidden");

			// Dispatch the hide event
			const hideEvent = new CustomEvent(this.hideEventName);
			document.dispatchEvent(hideEvent);
		}
	}
}
