/**
 * Translator class for handling multilingual translations on a web page.
 */
export default class Translator {
	/**
	 * Create a new Translator instance.
	 * @param {string} [language='nl'] - The default language.
	 */
	constructor(language = "nl") {
		this.language = language;
		this.translations = {};

		this.initialize();
	}

	/**
	 * Fetches the translations for the specified language.
	 * @param {string} language - The language code.
	 * @returns {Promise<void>} A promise that resolves when translations are fetched.
	 */
	async fetchTranslations(language) {
		try {
			const response = await fetch(`locales/${language}.json`);
			const translations = await response.json();
			this.translations[language] = translations;
		} catch (error) {
			console.error(`Error fetching translations for ${language}:`, error);
		}
	}

	/**
	 * Translates the page content based on the current language.
	 * @param {string} language - The language code.
	 */
	translatePage(language) {
		const elements = document.querySelectorAll("[data-translate]");

		elements.forEach((element) => {
			const translationKey = element.dataset.translate;
			const translationKeys = translationKey.split(".");
			let translatedText = this.translations[language];

			translationKeys.forEach((key) => {
				if (!translatedText) {
					console.warn(`Translation not found for key: ${translationKey}`);
					return;
				}
				translatedText = translatedText[key];
			});

			element.textContent = translatedText || "Translation missing";
		});
	}

	/**
	 * Initializes the Translator instance by fetching translations and translating the page.
	 * @returns {Promise<void>} A promise that resolves when initialization is complete.
	 */
	async initialize() {
		try {
			await this.fetchTranslations(this.language);
			this.translatePage(this.language);
		} catch (error) {
			console.error("Error initializing Translator:", error);
			// Handle the error gracefully, e.g., display an error message to the user
		}
	}

	/**
	 * Updates the language and retranslates the page.
	 * @param {string} language - The new language code.
	 */
	updateLanguage(language) {
		if (language === this.language) {
			return;
		}

		this.language = language;
		this.translatePage(language);
	}
}
