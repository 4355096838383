export const SUPPORTED_LANGUAGES = ["en", "fr", "de", "nl", "da"];
export const ORIGIN_POSTAL_CODE = "1782SP";

export const EVENT_LOCATION_RETRIEVED = "location.retrieved";
export const EVENT_INPUT_UPDATED = "input.updated";

export const ELEMENT_IDS = {
	locationIcon: "locationIcon",
	postalCodeSubmit: "postal_code_submit",
	callLink: "call-link",
	iconsContainer: "icons-container",
	chatLink: "chat-link",
	iconsContainer2: "icons-container2",
	menuOpen: "menu-open",
	menuItems: "menu-items",
	postalCodeInput: "postalCodeInput",
	heroFormContainer: "hero-form-container",
	modal: "modal",
	quoteForm: "quoteForm",
};

export const CSS_CLASSES = {
	modalClose: ".modal-close",
};
