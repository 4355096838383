export class FormInputTransformer {
	/**
	 * Creates an instance of FormInputTransformer.
	 */
	constructor() {
		this.inputFields = document.querySelectorAll("[data-transform]");
		this.initializeTransformers();
	}

	/**
	 * Initializes the transformers for each input field.
	 */
	initializeTransformers() {
		this.inputFields.forEach((input) => {
			const transformType = input.getAttribute("data-transform");
			const transformer = this.getTransformer(transformType);

			if (transformer) {
				input.addEventListener("blur", () => {
					this.transformInput(input, transformer);
				});
			}
		});
	}

	/**
	 * Retrieves the appropriate transformer function based on the transform type.
	 * @param {string} transformType - The transform type.
	 * @returns {Function|null} The transformer function or null if not found.
	 */
	getTransformer(transformType) {
		const transformers = {
			name: this.transformName,
			email: this.transformEmail,
			phone: this.transformPhoneNumber,
			postalCode: this.transformPostalCode,
			creditCard: this.transformCreditCardNumber,
			date: this.transformDate,
			ssn: this.transformSSN,
			url: this.transformURL,
		};

		return transformers[transformType] || null;
	}

	/**
	 * Transforms the value of an input field using the specified transformer.
	 * @param {HTMLInputElement} input - The input field.
	 * @param {Function} transformer - The transformer function.
	 */
	transformInput(input, transformer) {
		const value = input.value.trim();
		const transformedValue = transformer.call(this, value);
		input.value = transformedValue;
	}

	/**
	 * Transforms a name value by capitalizing each word.
	 * @param {string} value - The name value.
	 * @returns {string} The transformed name value.
	 */
	transformName(value) {
		const words = value.split(" ");
		const transformedWords = words.map((word) => this.capitalizeWord(word));
		return transformedWords.join(" ");
	}

	/**
	 * Transforms an email value to lowercase.
	 * @param {string} value - The email value.
	 * @returns {string} The transformed email value.
	 */
	transformEmail(value) {
		return value.toLowerCase();
	}

	/**
	 * Transforms a phone number value to a specific format.
	 * @param {string} value - The phone number value.
	 * @returns {string} The transformed phone number value.
	 */
	transformPhoneNumber(value) {
		const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters
		const transformedValue = numericValue.replace(
			/(\d{3})(\d{3})(\d{4})/,
			"($1) $2-$3"
		);
		return transformedValue;
	}

	/**
	 * Transforms a postal code value to uppercase and removes non-alphanumeric characters.
	 * @param {string} value - The postal code value.
	 * @returns {string} The transformed postal code value.
	 */
	transformPostalCode(value) {
		const alphanumericValue = value.replace(/\W/g, ""); // Remove non-alphanumeric characters
		return alphanumericValue.toUpperCase();
	}

	/**
	 * Transforms a credit card number value by adding spaces between groups of four digits.
	 * @param {string} value - The credit card number value.
	 * @returns {string} The transformed credit card number value.
	 */
	transformCreditCardNumber(value) {
		const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters
		const transformedValue = numericValue.replace(/(\d{4})/g, "$1 ").trim();
		return transformedValue;
	}

	/**
	 * Transforms a date value to a localized date string.
	 * @param {string} value - The date value.
	 * @returns {string} The transformed date value.
	 */
	transformDate(value) {
		const transformedValue = new Date(value).toLocaleDateString();
		return transformedValue;
	}

	/**
	 * Transforms an SSN (Social Security Number) value to a specific format.
	 * @param {string} value - The SSN value.
	 * @returns {string} The transformed SSN value.
	 */
	transformSSN(value) {
		const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters
		const transformedValue = numericValue.replace(
			/(\d{3})(\d{2})(\d{4})/,
			"$1-$2-$3"
		);
		return transformedValue;
	}

	/**
	 * Transforms a URL value to lowercase.
	 * @param {string} value - The URL value.
	 * @returns {string} The transformed URL value.
	 */
	transformURL(value) {
		return value.toLowerCase();
	}

	/**
	 * Capitalizes the first character of a word and converts the rest to lowercase.
	 * @param {string} word - The word to capitalize.
	 * @returns {string} The capitalized word.
	 */
	capitalizeWord(word) {
		const firstChar = word.charAt(0).toUpperCase();
		const remainingChars = word.slice(1).toLowerCase();
		return firstChar + remainingChars;
	}
}
