import { Loader } from "@googlemaps/js-api-loader";

export class PostalCodeRangeChecker {
	constructor() {
		this.loader = new Loader({
			apiKey: "AIzaSyDJinpNFSuhkaHWBRvGhoiNmC9H0VJv8cc",
			version: "weekly",
			libraries: ["geometry", "places"],
		});
	}

	async checkPostalCodeInRange(originPostalCode, targetPostalCode) {
		try {
			await this.loader.load();

			const originLocation = await this.getPostalCodeLocation(originPostalCode);
			const targetLocation = await this.getPostalCodeLocation(targetPostalCode);

			const directionsService = new google.maps.DirectionsService();

			const request = {
				origin: originLocation,
				destination: targetLocation,
				travelMode: google.maps.TravelMode.DRIVING,
			};

			const response = await new Promise((resolve, reject) => {
				directionsService.route(request, (result, status) => {
					if (status === google.maps.DirectionsStatus.OK) {
						resolve(result);
					} else {
						reject(new Error(`Failed to calculate directions: ${status}`));
					}
				});
			});

			const route = response.routes[0];
			const duration = route.legs[0].duration.value; // Travel duration in seconds

			const isWithinRange = duration <= 3600; // Check if duration is within one hour (3600 seconds)

			return isWithinRange;
		} catch (error) {
			console.error("Error checking range:", error);
			throw new Error("Failed to check postal code range.");
		}
	}

	getPostalCodeLocation(postalCode) {
		return new Promise((resolve, reject) => {
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode(
				{ address: postalCode, componentRestrictions: { country: "NL" } },
				(results, status) => {
					if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
						const location = results[0].geometry.location;
						resolve(location);
					} else {
						reject(new Error(`Failed to geocode postal code: ${postalCode}`));
					}
				}
			);
		});
	}
}
