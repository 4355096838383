export class CollapsibleMenu {
	constructor(menuToggleSelector, menuSelector, animationHandler) {
		if (
			typeof document.querySelector !== "function" ||
			!("classList" in document.documentElement)
		) {
			throw new Error(
				"Unsupported browser. Please upgrade to a modern browser."
			);
		}

		if (!menuToggleSelector || !menuSelector) {
			throw new Error("Menu toggle selector and menu selector are required");
		}

		this.menuToggleSelector = menuToggleSelector;
		this.menuSelector = menuSelector;
		this.menuToggle = null;
		this.menu = null;
		this.hiddenClass = "hidden";
		this.isOpen = false;
		this.animationHandler = animationHandler || null;

		this.initialize();
	}

	initialize() {
		this.menuToggle = document.querySelector(this.menuToggleSelector);
		this.menu = document.querySelector(this.menuSelector);

		if (!this.menuToggle) {
			throw new Error(
				`Menu toggle element not found with selector: ${this.menuToggleSelector}`
			);
		}

		if (!this.menu) {
			throw new Error(
				`Menu element not found with selector: ${this.menuSelector}`
			);
		}

		this.menuToggle.setAttribute("aria-expanded", "false");
		this.menu.setAttribute("aria-hidden", "true");

		// this.menuToggle.addEventListener("click", this.toggleMenu.bind(this));
	}

	openMenu() {
		this.menuToggle.setAttribute("aria-expanded", "true");
		this.menu.setAttribute("aria-hidden", "false");

		if (this.animationHandler) {
			this.animationHandler.startAnimation(this.menu);
		} else {
			this.menu.classList.remove(this.hiddenClass);
		}

		this.isOpen = true;
		this.focusFirstMenuItem();
	}

	closeMenu() {
		this.menuToggle.setAttribute("aria-expanded", "false");
		this.menu.setAttribute("aria-hidden", "true");

		if (this.animationHandler) {
			this.animationHandler.reverseAnimation(this.menu);
		} else {
			this.menu.classList.add(this.hiddenClass);
		}

		this.isOpen = false;
	}

	focusFirstMenuItem() {
		const firstMenuItem = this.menu.querySelector("[tabindex='0']");
		if (firstMenuItem) {
			firstMenuItem.focus();
		}
	}

	destroy() {
		this.menuToggle.removeEventListener("click", this.toggleMenu.bind(this));
	}

	toggleMenu() {
		if (this.isOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}
}
