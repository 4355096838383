/**
 * LanguageDetector class detects the user's preferred language based on the supported languages provided.
 */
export class LanguageDetector {
	/**
	 * Creates an instance of LanguageDetector.
	 * @param {string[]} supportedLanguages - The array of supported language codes.
	 */
	constructor(supportedLanguages) {
		/**
		 * The array of supported language codes.
		 * @type {string[]}
		 */
		this.supportedLanguages = supportedLanguages;
	}

	/**
	 * Detects the user's preferred language.
	 * @returns {string} The detected language code.
	 */
	detectLanguage() {
		const preferredLanguage = this.getPreferredLanguage();
		const detectedLanguage = this.getSupportedLanguage(preferredLanguage);
		return detectedLanguage || this.getDefaultLanguage();
	}

	/**
	 * Retrieves the user's preferred language from the browser settings.
	 * @returns {string} The preferred language code.
	 */
	getPreferredLanguage() {
		const browserLanguages = [
			...(navigator.languages || []),
			navigator.language,
			navigator.userLanguage,
			navigator.browserLanguage,
			navigator.systemLanguage,
		];
		return browserLanguages.find((language) =>
			this.supportedLanguages.includes(language.toLowerCase())
		);
	}

	/**
	 * Retrieves the supported language based on the provided language code.
	 * @param {string} languageCode - The language code to check against the supported languages.
	 * @returns {string} The supported language code.
	 */
	getSupportedLanguage(languageCode) {
		const normalizedLanguageCode = languageCode.toLowerCase();
		return this.supportedLanguages.find((language) =>
			normalizedLanguageCode.startsWith(language)
		);
	}

	/**
	 * Retrieves the default language.
	 * @returns {string} The default language code.
	 */
	getDefaultLanguage() {
		const detectedLocale = navigator.language || navigator.userLanguage;
		const detectedCountry = detectedLocale.split("-")[1];
		if (detectedCountry === "NL") {
			return "nl"; // Default to Dutch for users located in the Netherlands
		} else if (this.supportedLanguages.includes("en")) {
			return "en"; // Default to English if available as a supported language
		} else {
			return this.supportedLanguages[0]; // Use the first supported language as the default
		}
	}
}
