/**
 * Returns the first element that matches the given selector within the specified parent element.
 * @param {string} selector - The CSS selector to match.
 * @param {HTMLElement} [parent=document] - The parent element to search within.
 * @returns {HTMLElement|null} The first matching element, or null if no element is found.
 */
export function qs(selector, parent = document) {
  try {
    return parent.querySelector(selector);
  } catch (error) {
    console.error(`Error querying selector '${selector}':`, error);
    return null;
  }
}

/**
 * Returns an array of elements that match the given selector within the specified parent element.
 * @param {string} selector - The CSS selector to match.
 * @param {HTMLElement} [parent=document] - The parent element to search within.
 * @returns {HTMLElement[]} An array of matching elements.
 */
export function qsa(selector, parent = document) {
  try {
    return [...parent.querySelectorAll(selector)];
  } catch (error) {
    console.error(`Error querying selector '${selector}':`, error);
    return [];
  }
}

/**
 * Adds an event listener to the parent element that listens for the specified event type on elements matching the given selector.
 * @param {string|string[]} types - The event type(s) to listen for.
 * @param {string} selector - The CSS selector to match.
 * @param {function} callback - The callback function to execute when the event is triggered.
 * @param {object} [options] - Additional options to configure the event listener.
 * @param {HTMLElement} [parent=document] - The parent element to attach the event listener to.
 */
export function addGlobalEventListener(
  types,
  selector,
  callback,
  options,
  parent = document
) {
  if (!Array.isArray(types)) {
    types = [types];
  }

  types.forEach((type) => {
    parent.addEventListener(
      type,
      (e) => {
        if (e.target.matches(selector)) callback(e);
      },
      options
    );
  });
}

/**
 * Executes the callback function when the DOM is ready.
 * @param {function} callback - The callback function to execute.
 */
export function onDOMReady(callback) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    callback();
  }
}

/**
 * Delegates an event to the parent element(s) using event delegation.
 * @param {string} eventType - The event type to listen for.
 * @param {string} selector - The CSS selector to match.
 * @param {HTMLElement|HTMLElement[]} parent - The parent element(s) to attach the event listener to.
 * @param {function} callback - The callback function to execute when the event is triggered.
 * @param {object} [options] - Additional options to configure the event listener.
 */
export function delegateEvent(eventType, selector, parent, callback, options) {
  addGlobalEventListener(eventType, selector, callback, options, parent);
}

/**
 * Creates a debounced version of a function that delays its execution until after a specified time period has elapsed.
 * @param {function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {function} The debounced function.
 */
export function debounce(func, delay) {
  let timerId;
  return function (...args) {
    const context = this; // Preserve the context
    clearTimeout(timerId);
    timerId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}

/**
 * Creates a throttled version of a function that limits its execution to a specified interval.
 * @param {function} func - The function to throttle.
 * @param {number} interval - The interval in milliseconds.
 * @returns {function} The throttled function.
 */
export function throttle(func, interval) {
  let lastExecTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastExecTime >= interval) {
      func.apply(this, args);
      lastExecTime = now;
    }
  };
}

/**
 * Formats a given date object or timestamp into a human-readable date string.
 * @param {Date|number} date - The date object or timestamp.
 * @param {string} [format="YYYY-MM-DD"] - The format string for the date representation.
 * @returns {string} The formatted date string.
 */
export function formatDate(date, format = "YYYY-MM-DD") {
  if (typeof date === "number") {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let formattedDate = format.replace("YYYY", year);
  formattedDate = formattedDate.replace("MM", month);
  formattedDate = formattedDate.replace("DD", day);

  return formattedDate;
}

/**
 * Generates a unique identifier.
 * @returns {string} The unique identifier.
 */
export function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}

/**
 * Shuffles the elements of an array randomly.
 * @param {Array} array - The array to shuffle.
 * @returns {Array} The shuffled array.
 */
export function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

/**
 * Creates a deep clone of an object.
 * @param {any} value - The value to clone.
 * @returns {any} The deep clone of the value.
 */
export function deepClone(value) {
  return JSON.parse(JSON.stringify(value));
}

/**
 * Formats a numeric value into a formatted string representation.
 * @param {number} number - The numeric value to format.
 * @param {object} [options] - The formatting options.
 * @returns {string} The formatted number string.
 */
export function formatNumber(number, options) {
  const defaultOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  };
  return number.toLocaleString(undefined, defaultOptions);
}

/**
 * Checks whether a given value is empty.
 * @param {any} value - The value to check.
 * @returns {boolean} True if the value is empty, false otherwise.
 */
export function isEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value) || typeof value === "string") {
    return value.length === 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function sanitizeInput(input) {
  // Remove leading and trailing whitespace
  const sanitizedInput = input.trim();

  // Remove HTML tags using a regular expression
  const sanitizedHTML = sanitizedInput.replace(/<[^>]+>/g, "");

  // Escape special characters to prevent code injection
  const sanitizedText = document.createElement("textarea");
  sanitizedText.innerHTML = sanitizedHTML;
  const sanitizedOutput = sanitizedText.value;

  return sanitizedOutput;
}

export function styleCheckbox(checkboxSelector, toggleClass) {
  let checkbox = document.querySelector(checkboxSelector);
  let label = checkbox.parentNode.querySelector(".checkbox-circle");

  if (checkbox && label) {
    checkbox.addEventListener("change", function () {
      label.classList.toggle(toggleClass);
    });
  } else {
    console.error("Checkbox or label not found");
  }
}
